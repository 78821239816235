import { BASE_URL } from './../consts';
import Vue from "vue";
import Vuex from "vuex";
import auth from "./auth";
import cart from "./cart";
import cabinet from "./cabinet";
import operator from "./operator";
import admin from "./admin";
import filter from "./filter";
import axios from "axios";
Vue.use(Vuex);

export default new Vuex.Store({
  state: {
    loading: false,
    doRequest: false,
    showMobMenu: false,
    goods: [
      {
        id: 1,
        photo: "https://via.placeholder.com/",
        name: "Круассан",
        kkal: "150",
        bju: "12/15/14",
        desc: "Круассан с малиновым джемом",
        price: 200,
      },
    ],
    category: [],
    loader: true,

  },
  mutations: {
    loading(state, payload) {
      state.loading = payload;
    },
    category_list(state, payload) {
      state.category = payload.category;
    },
    dish_list(state, payload) {
      state.goods = payload.dishList;
    },


    loader(state, payload) {
      state.loader = payload
    },
    mobMenu(state) {
      state.showMobMenu = !state.showMobMenu
    },
    doRequest(state, payload){
      state.doRequest = payload
    }
  },
  actions: {
    async actionGetCat({ commit }) {
      var res = await axios({
        url: `${BASE_URL}/groups`,
        method: "POST",
      });
      console.log(res.data)
      commit("category_list", {
        category: res.data,
      });
      return res;
    },

    async actionGetDish({ commit }, data) {
      if (data.time.length != 5) {
        data.time = `0${data.time}`
      }
      var res = await axios({
        url: `${BASE_URL}/group/${data.id}`,
        data: data,
        method: "POST",
      });
      console.log('dish', res.data)
      commit("dish_list", {
        dishList: res.data,
      });
      return res;
    },


  },
  modules: {
    auth,
    cart,
    cabinet,
    operator,
    admin,
    filter
  },
});
