import { calculateAmount, calculateTotal, copyFunc } from "../function";
import {BASE_URL} from './../consts';
import axios from "axios";
export default {
  state: {
    cart: {
      "cart_items": [{
        "products": [{
          "quantity": null
        }]
      }],
  
    },
    cartEdit:{},
    delItem: {},
    TotalPositions: 0,
    Total: 0,
    date: sessionStorage.getItem("date"),
    hour: sessionStorage.getItem("time"),
    catId: 1,
    cartEditId: localStorage.getItem("cartEditId") || null
  },
  mutations: {
    SET_DATE(state, payload) {
      state.date = payload;
     },
     SET_HOUR(state, payload) {
      state.hour = payload;
     },
     SET_CATID(state, payload) {
      state.catId = payload;
     },

     SET_CART: (state, item) => {
      state.cart = item.cart
    },
    SET_CART_EDIT: (state, item) => {
      state.cartEdit = item.cart
    },
    SET_CART_ID_EDIT: (state, item) => {
      state.cartEditId = item
    },
    REMOVE_ITEM: (state, item) => {
      state.delItem = {};
      state.delItem = copyFunc(state.cart, state.delItem, item);
      delete state.cart[item];
      state.Total = calculateAmount(state.cart);
      state.TotalPositions = calculateTotal(state.cart);
    },
    CHENGE_QT: state => {
      state.TotalPositions = calculateTotal(state.cart);
      state.Total = calculateAmount(state.cart);
    },
  },

  actions: {
   // addToCart: ({ commit }, item) => commit("ADD_ITEM", item),
   // removeItem: ({ commit }, item) => commit("REMOVE_ITEM", item),


    async actionAddToCart(_,date) {
     console.log(date)
      try {
       const res = await axios({
          url: `${BASE_URL}/cart/add/${date.id}?date_time=${date.date} ${date.hour}:00`,
          //data: { phone },
          method: "POST",
        });
       console.log(res.data)
       return res.data;
      } catch (error) {
        console.log('qwdqdqd',error.response.data)
        return error.response.data
      }

    },

    async actionGetCart({ commit }) {
      try {
        const res = await axios({url:`${BASE_URL}/cart`})
        console.log(res)
        commit("SET_CART", {
          cart: res.data,
        });
      } catch (error) {
        console.log(error)
      }

    },

    async actionIncrease({ commit }, data) {
      try {
        const res = await axios({ url: `${BASE_URL}/cart/product/increase?cart_item_id=${data.cartItemId}&product_id=${data.productId}`,
        method: "POST",
      });
        console.log(res)
        commit("smsResult", {
          // smsResult: res.data.message,
        });
        return res;
      } catch (error) {
        console.log(error)
      }

    },

    async actionIncreaseEdit({ commit }, data) {
      try {
        
        const res = await axios({ url: `${BASE_URL}/cart/product/increase?product_id=${data.productId}&cart_item_id=${data.cartItemId}`,

        method: "POST",
      });
        console.log(res)
        commit("smsResult", {
          // smsResult: res.data.message,
        });
      } catch (error) {
        console.log(error)
      }

    },
    

    async actionDecrease({ commit }, data) {
      try {
        const res = await axios({ url: `${BASE_URL}/cart/product/decrease?cart_item_id=${data.cartItemId}&product_id=${data.productId}`,
        method: "POST",
      
      });
        console.log(res)
        commit("smsResult", {
          // smsResult: res.data.message,
        });
      } catch (error) {
        alert(error)
      }

    },

    async actionDecreaseEdit({ commit }, data) {

      try {
        const res = await axios({ url: `${BASE_URL}/cart/product/decrease?cart_item_id=${data.cartItemId}&product_id=${data.productId}`,
        method: "POST",
      
      });
        console.log(res)
        commit("smsResult", {
          // smsResult: res.data.message,
        });
      } catch (error) {
        alert(error)
      }

    },

    async actionRemoveFromCart({ commit }, data) {
      try {
        const res = await axios({ url: `${BASE_URL}/cart/product/remove?cart_item_id=${data.cartId}&product_id=${data.id}`, 
        method: "POST",
      });
        console.log(res)
        commit("smsResult", {
          // smsResult: res.data.message,
        });
      } catch (error) {
        alert(error)
      }

    },
    async actionCreateOrder(_,data) {
      try {
      
        const res = await axios({ url: `${BASE_URL}/auth/cart/order`,
        method: "POST",
        data:data
      });
      return res;

        // commit("smsResult", {
        //   // smsResult: res.data.message,
        // });
      } catch (error) {
        alert(error)
      }

    },
    async actionSelectTime(_,data) {
      try {
        const res = await axios({ url: `${BASE_URL}/cart/select/time`,
        method: "POST",
        data
      });
        console.log(res)
     
      } catch (error) {
        console.log(error)
      }

    },
    async actionGetCartEdit({ commit },id) {
      try {
        const res = await axios({url:`${BASE_URL}/personal/order/info/${id}`})
        console.log(res)
        commit("SET_CART_EDIT", {
          cart: res.data,
        });
      } catch (error) {
        alert(error.response.data.message)
      }

    },
    async actionAddToCartEdit(_,date) {
       try {
         const res = await axios({
          
           url: `${BASE_URL}/personal/order/product/add?product_id=${date.id}&cart_item_id=${date.cartId}`,
           //data: { phone },
           method: "POST",
         });
          return res.data
       } catch (error) {
         //alert(error.response.data.message)
         return error.response.data;
       }
 
     },
    // async actionSendSms({ commit }, phone) {
    //   const res = await axios({
    //     url: "https://elimleli2.itfactory.site/api/v1/auth/??",
    //     data: { phone },
    //     method: "POST",
    //   });
    //   alert("Код из СМС:  " + res.data.code);
    //   commit("smsResult", {
    //     // smsResult: res.data.message,
    //   });
    // },

    // async payInternalAccount({ commit }, sum) {
    //   const res = await axios({
    //     url: "https://elimleli2.itfactory.site/api/v1/auth/??",
    //     data: { sum },
    //     method: "POST",
    //   });
    //   commit("payResult", {
    //     payResult: res.data.message,
    //   });
    // },

    // async confirmOrder({ commit }, sum) {
    //   const res = await axios({
    //     url: "https://elimleli2.itfactory.site/api/v1/auth/??",
    //     data: { sum },
    //     method: "POST",
    //   });
    //   commit("confirmResult", {
    //     confirmResult: res.data.message,
    //   });
    // }
  },


  getters: {
    countCartItems: (state) => {
      let arr = 0
      if(state.cart.cart_items){
      state.cart.cart_items.forEach(element => {
        //console.log('asdadadad',element.products[0].quantity)
     
        element.products.forEach(element => {
          arr += element.quantity;
        });

        
      });
    }
      return arr;
    },

  },
};
