<template>
<div class="app-loader">
    <div class="preloader-wrapper active">
        <div class="spinner-layer" :class="color">
           <div class="spinner-border text-secondary" role="status">
</div>
        
        </div>
    </div>
</div>
</template>

<script>
export default {
computed: {
    color(){
        const colors = ['spinner-red-only','spinner-blue-only','spinner-green-only']
        return colors[Math.floor(Math.random()*3)]
    }
},
}
</script>
<style lang="scss">
    .app-loader{
        position: absolute;
        width: 100%;
        left: 0;
        right: 0;
        bottom: 0;
        top: 0;
        background: #F5F5F5;
        z-index: 2222;
        display: flex;
        justify-content: center;
        align-items: center;
opacity: .8;
    }


</style>
